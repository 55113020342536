<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0">
        <v-card-title>
          <span>Requests</span>

          <router-link
            class="link"
            :to="`/customerView/${customerId}/requests/new`"
            ><v-btn small class="ml-5">New Request</v-btn></router-link
          >
        </v-card-title>
        <v-card-text class="mt-3">
          <!-- Start Requests section -->
          <v-row>
            <v-col>
              <!-- <v-row>
                <v-col cols="12" md="2">
                  <v-select
                    v-model="viewByPriority"
                    :items="viewByPriorityItems"
                    item-text="name"
                    item-value="value"
                    label="Choose Priority..."
                  ></v-select>
                </v-col>
              </v-row> -->

              <v-tabs
                v-model="tab"
                background-color="transparent"
                centered
                grow
              >
                <v-tab href="#tab-1" v-if="!isResident">
                  Due This Week ({{ dueCount }})
                </v-tab>

                <v-tab href="#tab-2"> All Open ({{ openCount }}) </v-tab>

                <v-tab href="#tab-3"> In Process ({{ inProcessCount }}) </v-tab>

                <v-tab href="#tab-4"> Closed ({{ closedCount }}) </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="i in 4" :key="i" :value="'tab-' + i">
                  <v-container fluid>
                    <v-row>
                      <v-col v-if="isPropertyManager">
                        <v-btn
                          class="orange-bg white--text mt-4"
                          @click="getExportFile"
                          small
                        >
                          Export
                        </v-btn></v-col
                      >
                      <v-col
                        ><v-text-field
                          v-model="search"
                          label="Search"
                          prepend-inner-icon="mdi-magnify"
                          variant="outlined"
                          hide-details
                          single-line
                        ></v-text-field
                      ></v-col>
                    </v-row>

                    <v-data-table
                      :headers="headers"
                      :items="records"
                      :search="search"
                      item-key="id"
                      :loading="isLoading"
                      :items-per-page="50"
                      :footer-props="{
                        'items-per-page-options': [50, 100, 150, 200, -1]
                      }"
                      :options.sync="options"
                      loading-text="Loading... Please wait"
                      class="elevation-1"
                    >
                      <template #[`item.requestTypeName`]="{ item }">
                        <router-link
                          :to="
                            `/customerView/${item.communityId}/requests/${item.id}/update`
                          "
                          class="blue-color"
                        >
                          {{ getServiceRequestTypeName(item.requestType) }}
                        </router-link>
                      </template>
                      <template #[`item.comments`]="{ item }">
                        <span>{{ item.comments }} </span>
                      </template>
                      <template #[`item.streetLocation`]="{ item }">
                        <span>{{ item.location }} </span>
                      </template>
                      <template #[`item.dateCreated`]="{ item }">
                        <span
                          :style="
                            new Date(item.targetCompletionDate) < new Date()
                              ? 'color: red; font-weight: bold;'
                              : ''
                          "
                          >{{ formatDate(item.dateCreated) }}
                        </span>
                      </template>
                      <!-- <template #[`item.priorityId`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              :color="
                                item.priority.name === 'Normal'
                                  ? 'green'
                                  : item.priority.name === 'High'
                                  ? 'red'
                                  : 'blue'
                              "
                            >
                              fas fa-circle
                            </v-icon>
                          </template>
                          <span>{{ item.priority.name }}</span>
                        </v-tooltip>
                      </template> -->
                      <template #[`item.actions`]="{ item }">
                        <v-btn
                          v-if="
                            permissions &&
                              permissions.serviceRequest &&
                              permissions.serviceRequest.canDelete
                          "
                          class="ma-2"
                          text
                          icon
                          @click.stop="item.deleteDialog = true"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>

                        <v-dialog
                          v-model="item.deleteDialog"
                          persistent
                          max-width="290"
                        >
                          <v-card>
                            <v-card-title class="headline">
                              Delete Request
                            </v-card-title>
                            <v-card-text
                              >Are you sure you want to delete request:
                              <p>{{ item.title }}</p></v-card-text
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="green darken-1"
                                text
                                @click="item.deleteDialog = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="green darken-1"
                                text
                                @click="deleteRequest(item)"
                              >
                                Delete
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
          <!-- End Requests section -->
        </v-card-text>
      </v-card>
      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import {
  GET_QUERY,
  GET_PAGED,
  DELETE,
  API_CUSTOMERS,
  API_SERVICE_REQUESTS
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";
import Mgr from "@/core/services/security.service";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    mgr: new Mgr(),
    userInfo: null,
    permissions: {},
    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["dateCreated"],
      sortDesc: [true]
    },
    search: "",
    headers: [
      {
        text: "Date Created",
        align: "start",
        value: "dateCreated",
        class: "orange-bg"
      },
      { text: "Issue #", value: "id", class: "orange-bg" },
      {
        text: "Service Type",
        value: "requestTypeName",
        class: "orange-bg"
      },
      {
        text: "Property Address",
        value: "streetLocation",
        class: "orange-bg"
      },
      {
        text: "Description",
        value: "description",
        class: "orange-bg"
      },
      { text: "Assigned To", value: "assignedToFullName", class: "orange-bg" },
      // { text: "Priority", value: "priorityId", class: "orange-bg" },
      {
        text: "Comments",
        value: "comments",
        class: "orange-bg",
        sortable: false
      },
      { text: "Actions", value: "actions", class: "orange-bg", sortable: false }
    ],
    records: [],
    totalRecords: null,

    // viewByPriority: "all",
    // viewByPriorityItems: [
    //   { name: "View All priorities", value: "all" },
    //   { name: "Low", value: "Low" },
    //   { name: "Normal", value: "Normal" },
    //   { name: "High", value: "High" }
    // ],

    tab: null,

    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    newCount: 0,
    openCount: 0,
    dueCount: 0,
    inProcessCount: 0,
    closedCount: 0
  }),

  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    // options: async function() {
    //   await this.getRequests();
    // },
    // "options.sortBy": async function() {
    //   await this.getRequests();
    // },
    // viewByPriority: async function() {
    //   await this.getRequests();
    // },
    tab: async function() {
      await this.getRequests();
    }
  },
  async mounted() {
    await this.getRequests();
  },
  async created() {
    this.userInfo = await this.mgr.getUser(); //.then(userInfo => (this.userInfo = userInfo));
    permissionsHelper.getPermissions().then(this.getPermissions);
    await this.getComunityInfo();
  },
  methods: {
    getServiceRequestTypeName(requestType) {
      if (requestType) return requestType.name;
      return "";
    },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerView",
        params: { customerId: communityId }
      });
    },
    deleteRequest(item) {
      let self = this;
      if (!item) return;

      this.$store
        .dispatch(DELETE, {
          listName: `${API_SERVICE_REQUESTS}`,
          id: item.id
        })
        .then(function() {
          item.deleteDialog = false;

          self.getRequests();
        });
    },
    async getComunityInfo() {
      if (this.loading) return;
      this.loading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);

            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Requests" }
      ];
    },

    setStartingAfter() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.startingAfter = this.userInvoices[this.userInvoices.length - 1].id;
        this.endingBefore = "";
      }
    },
    setEndingBefore() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.endingBefore = this.userInvoices[0].id;
        this.startingAfter = "";
      }
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Service Requests" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    async getExportFile() {
      let url = `${process.env.VUE_APP_API_URL}${API_SERVICE_REQUESTS}/Community/${this.customerId}/export`;

      url += `?mediumFormat=${true}&uid=${this.userInfo.id}&userRole=${
        this.userInfo.role
      }`;

      window.open(url, "_blank");
    },
    async getRequests() {
      if (this.isLoading) return;
      this.isLoading = true;

      await this.$store
        // .dispatch(GET_QUERY, {
        //   listName: `${API_SERVICE_REQUESTS}/Community`,
        //   id: this.customerId
        // })
        .dispatch(GET_PAGED, {
          listName: `${API_SERVICE_REQUESTS}/Community/${this.customerId}`,
          params: { mediumFormat: true }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            //          const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const { sortBy, sortDesc } = this.options;
            let items = response.data.$values.filter(el => !el.isInternal);

            let newItems = [];
            let dueItems = [];
            let openItems = [];
            let inProcessItems = [];
            let closedItems = [];

            let endWeekDate = moment
              .utc()
              .endOf("isoWeek")
              .toDate();

            dueItems = items.filter(
              el =>
                moment.utc(el.targetCompletionDate).toDate() < endWeekDate &&
                !el.isCompleted &&
                !el.isNew
            );
            this.dueCount = dueItems.length;
            closedItems = items.filter(el => el.isCompleted);
            this.closedCount = closedItems.length;
            inProcessItems = items.filter(el => el.isInProcess);
            this.inProcessCount = inProcessItems.length;
            newItems = items.filter(el => el.isNew);
            this.newCount = newItems.length;

            if (this.isResident)
              openItems = items.filter(
                el => el.isOpen || el.isInProcess || el.isNew
              );
            else openItems = items.filter(el => el.isOpen || el.isInProcess);

            this.openCount = openItems.length;

            if (this.tab === "tab-1") {
              // due this week
              items = dueItems;
            } else if (this.tab === "tab-4") {
              // closed
              items = closedItems;
            } else if (this.tab === "tab-3") {
              // in process
              items = inProcessItems;
            } else {
              // all open
              items = openItems;
            }

            // if (this.tab === "tab-1") {
            //  // due this week
            //   let endWeekDate = moment()
            //     .endOf("isoWeek")
            //     .toDate();

            //   items = items.filter(
            //     el =>
            //       moment(el.targetCompletionDate).toDate() < endWeekDate &&
            //       !el.isCompleted
            //   );
            // }

            // if (this.tab === "tab-4") {
            //  // closed
            //   items = items.filter(el => el.isCompleted);
            // } else if (this.tab === "tab-3") {
            // // in process
            //   items = items.filter(el => el.isInProcess);
            // } else {
            // // all open
            //   if (this.isResident)
            //     items = items.filter(
            //       el => el.isOpen || el.isInProcess || el.isNew
            //     );
            //   else items = items.filter(el => el.isOpen || el.isInProcess);
            // }

            items.forEach(function(element) {
              element.deleteDialog = false;
              element.assignedToFullName = element.assignedTo?.fullName;
              // element.priorityId = element.priority.id;
              element.requestTypeName = element.requestType?.name;
              if (element.location)
                element.streetLocation = element.location
                  .replace(/\b\d+/i, "")
                  .trimStart();
              else element.streetLocation = "";

              // let comments = element.comments.$values;
              // if (comments.length > 0) {
              //   let lastComment = comments.sort((a, b) => {
              //     return new Date(b.created) - new Date(a.created);
              //   })[0];
              //   if (lastComment && lastComment.assignedTo) {
              //     element.assignedTo = lastComment.assignedTo;
              //     element.assignedToFullName = element.assignedTo.fullName;
              //   }

              //   if (lastComment && lastComment.targetCompletionDate) {
              //     element.targetCompletionDate =
              //       lastComment.targetCompletionDate;
              //   }

              //   if (lastComment.requestType) {
              //     element.requestType = lastComment.requestType;
              //     element.requestTypeId = lastComment.requestType.id;
              //   }
              //   // if (lastComment.priority) {
              //   //   element.priority = lastComment.priority;
              //   //   element.priorityId = element.priority.id;
              //   // }
              // }
            });

            // if (this.viewByPriority && this.viewByPriority !== "all") {
            //   let viewByPriority = this.viewByPriority;
            //   items = items.filter(el => el.priority.name === viewByPriority);
            // }

            const total = items.length;

            if (sortBy.length === 1 && sortDesc.length === 1) {
              items = items.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              });
            }

            // if (itemsPerPage > 0) {
            //   items = items.slice(
            //     (page - 1) * itemsPerPage,
            //     page * itemsPerPage
            //   );
            // }
            this.records = items;
            this.totalRecords = total;
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.isLoading = false;
        });
    }
  },
  computed: {
    isResident() {
      return (
        this.userInfo?.profile?.aspireRole ==
          "Tenant/Resident - Neighborhood" ||
        this.userInfo?.profile?.aspireRole == "Tenant/Resident"
      );
    },
    isPropertyManager() {
      return this.userInfo?.profile?.aspireRole == "Property Manager";
    },
    sort() {
      if (
        this.options.sortBy &&
        this.options.sortBy[0] &&
        this.options.sortBy[0].length > 0
      ) {
        if (this.options.sortBy[0] === "fullName") {
          return `firstName ${this.options.sortDesc[0] ? "desc" : "asc"}`;
        } else {
          return `${this.options.sortBy[0]} ${
            this.options.sortDesc[0] ? "desc" : "asc"
          }`;
        }
      }
      return "";
    }
  }
};
</script>
